<template>
  <div>
    <div class="columns profile-box">
      <div class="page-box-content column" @keyup.enter="submit()">
        <div class="columns is-justify-content-center">
          <div class="column">
            <div class="personal-image d-flex align-items-center">
              <label class="label">
                <figure class="personal-figure">
                  <img :src="avatar " v-if="avatar"
                       class="personal-avatar" alt="avatar">
                  <img src="../../assets/svgs/ic_avatar_profile.svg" v-else
                       class="personal-avatar" alt="avatar">
                </figure>
              </label>
              <label class="label">
                <input type="file" @change="changeAvatar" accept=".jpg,.jpeg,.png,.gif"/>
                <div class="edit-avatar d-flex align-items-center c-pointer">
                  <img width="18" src="../../assets/svgs/ic_edit_2.svg"/>
                  <span class="fs-14">{{$t('profile.edit')}}</span>
                </div>
              </label>
            </div>
            <span class="error pt-1" v-if="!vuelidate.profile.avatar.fileSizeValidation && vuelidate.profile.$dirty">
                  {{ $t('profile.messages.validation.avatar.file_size') }}
            </span>
            <span class="error pt-1" v-if="!vuelidate.profile.avatar.extension && vuelidate.profile.$dirty">
                  {{ $t('profile.messages.validation.avatar.format') }}
            </span>
          </div>
        </div>
        <hr/>
        <div class="columns information m-0">
          <div class="column p__right--25 p__left--0">
            <div class="is-full information-input">
              <div>
                <span class="input-profile">{{ $t('profile.input_field.employee_id.label') }}<i
                    :data-tooltip="$t('tooltip.required')"
                    class="required-note">*</i></span>
              </div>
              <div>
                <input class="input" disabled type="text" v-model="profile.employee_id">
              </div>
            </div>
            <div class="is-full information-input">
              <div>
                <span class="input-profile">{{ $t('profile.input_field.position.label') }}<i
                    :data-tooltip="$t('tooltip.required')"
                    class="required-note">*</i></span>
              </div>
              <div>
                <input class="input" type="text" v-model.trim="profile.position.name" disabled
                       v-if="profile.position">
              </div>
            </div>
            <div class="is-full information-input">
              <div>
                <span class="input-profile">{{ $t('profile.input_field.full_name.label') }}</span>
              </div>
              <div>
                <input
                    :placeholder="$t('profile.input_field.full_name.placeholder')" class="input"
                    type="text"
                    v-model.trim="profile.full_name">
                <template v-if="vuelidate.profile.$dirty">
                <span class="error pt-1" v-if="!vuelidate.profile.full_name.maxLength">
                  {{ $t('profile.messages.validation.full_name_maxlength') }}
                </span>
                </template>
              </div>
            </div>
            <div class="is-full information-input">
              <div>
                <span class="input-profile">{{ $t('profile.input_field.email.label') }}
                            <i :data-tooltip="$t('tooltip.required')"
                               class="required-note">*</i></span>
              </div>
              <div>
                <input
                    :class="{'is-error': ((!vuelidate.profile.email.required || !vuelidate.profile.email.validateEmail) && vuelidate.profile.$dirty) || (error_server.email && error_server.email.length)}"
                    :placeholder="$t('profile.input_field.email.placeholder')"
                    class="input"
                    type="text"
                    v-model.trim="profile.email">
              </div>
              <span class="error pt-1" v-if="error_server.email && error_server.email.length">
              {{ error_server.email[0]}}
            </span>
              <template v-else-if="vuelidate.profile.$dirty">
                <span class="error pt-1" v-if="!vuelidate.profile.email.required">
                  {{ $t('profile.messages.validation.email_required') }}
                </span>
                <span class="error pt-1" v-else-if="!vuelidate.profile.email.validateEmail">
                  {{ $t('profile.messages.validation.email_invalid') }}
                </span>
              </template>
            </div>
            <div class="is-full information-input">
              <div>
                <span class="input-profile">{{ $t('profile.input_field.password.label') }}</span>
              </div>
              <div class="field">
                <div class="control has-icons-right" @click="modalPassword">
                  <input :placeholder="$t('profile.input_field.password.placeholder')"
                         class="input c-pointer"
                         readonly
                         type="password" autocomplete="off" :name="Math.random()"
                         v-model.trim="password">
                  <span class="icon is-medium is-right">
                    <img width="25px" class="icon-input-user c-pointer" src="../../assets/svgs/ic_edit_2.svg" alt="">
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="column p__left--25 p__right--0">
            <div class="is-full information-input">
              <div>
                 <span class="input-profile">{{ $t('profile.input_field.office_name.label') }}<i
                     :data-tooltip="$t('tooltip.required')"
                     class="required-note">*</i></span>
              </div>
              <div>
                <input class="input input-infor" disabled type="text" v-model="office">
              </div>
            </div>
            <div class="is-full information-input">
              <div>
                <span class="input-profile">{{ $t('users_management.labels.input_field.user_name.label') }}<i
                    :data-tooltip="$t('tooltip.required')"
                    class="required-note">*</i></span>
              </div>
              <div>
                <input
                    :placeholder="$t('users_management.labels.input_field.user_name.placeholder')"
                    :class="{'is-error': ((!vuelidate.profile.name.required || !vuelidate.profile.name.maxLength) && vuelidate.profile.$dirty) || (error_server.name && error_server.name.length)}"
                    class="input input-infor" type="text"
                    v-model.trim="profile.name">
                <template v-if="vuelidate.profile.$dirty">
                <span class="error pt-1" v-if="!vuelidate.profile.name.required">
                  {{ $t('users_management.messages.validation.username_required') }}
                </span>
                  <span class="error pt-1" v-if="!vuelidate.profile.name.maxLength">
                  {{ $t('users_management.messages.validation.name_maxlength') }}
                </span>
                </template>
              </div>
            </div>
            <div class="is-full information-input">
              <div>
                <span class="input-profile"> {{ $t('profile.input_field.katakana_name.label') }}</span>
              </div>
              <div>
                <input
                    :class="{'is-error': (!vuelidate.profile.katakana_name.maxLength || !vuelidate.profile.katakana_name.katakana) && vuelidate.profile.$dirty}"
                    :placeholder="$t('profile.input_field.katakana_name.placeholder')" class="input input-infor"
                    type="text"
                    v-model.trim="profile.katakana_name"
                >
                  <template v-if="vuelidate.profile.$dirty">
                  <span class="error pt-1" v-if="!vuelidate.profile.katakana_name.katakana">
                    {{ $t('users_management.messages.validation.katakana_invalid') }}
                  </span>
                    <span class="error pt-1" v-if="!vuelidate.profile.katakana_name.maxLength">
                    {{ $t('users_management.messages.validation.katakana_maxlength') }}
                  </span>
                  </template>
              </div>
            </div>
            <div class="is-full information-input">
              <div>
                <span class="input-profile">{{ $t('profile.input_field.phone.label') }}</span>
              </div>
              <div>
                <input
                    :class="{'is-error':(!vuelidate.profile.phone.maxLength && vuelidate.profile.$dirty) || (error_server.phone && error_server.phone.length)}"
                    :placeholder="$t('profile.input_field.phone.placeholder')"
                    class="input input-infor"
                    type="type"
                    autocomplete="off"
                    v-model.trim="profile.phone"
                    @keypress="isNumber($event)"
                >
                <template v-if="vuelidate.profile.$dirty">
                  <span class="error pt-1" v-if="!vuelidate.profile.phone.maxLength">
                    {{ $t('profile.messages.validation.phone_maxlength') }}
                  </span>
                  <span class="error pt-1" v-else-if="!vuelidate.profile.phone.minLength">
                    {{ $t('profile.messages.validation.phone_minlength') }}
                  </span>
                  <span class="error pt-1" v-else-if="!vuelidate.profile.phone.decimal">
                    {{ $t('profile.messages.validation.phone_decimal') }}
                  </span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div class="columns">
          <div class="column is-full">
            <div class="field is-grouped is-grouped-centered">
              <div class="control">
                <button @click="submit()" class="button btn-save">{{
                  $t('profile.buttons.save')
                  }}
                </button>
              </div>
              <div class="control">
                <button @click="reset()" class="button btn-back ">{{
                  $t('profile.buttons.reset')
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="modal-change-pass">
      <div class="modal-background"></div>
      <div class="modal-card" v-click-out="() =>{closeModal('modal-change-pass'); cancelCreateFolder}">
        <div class="modal-card-head background__gray_dark">
          <p class="modal-card-title input-title color__blue_main fs-20 text-center fw__bold">
            {{ $t('users_management.labels.change.password.title') }}
          </p>
          <button class="delete" aria-label="close" @click="closeModal('modal-change-pass')"></button>
        </div>
        <section class="modal-card-body change-pass-modal">
          <div class="modal-field-content">
            <div class="is-full m__top--10">
              <label class="input-title fs-14 color__blue_main fw__bold">{{ $t('users_management.labels.change.password.label') }}
              </label>
            </div>
            <div class="is-full control">
              <input type="password" class="input input-modal"
                     :placeholder="$t('users_management.labels.change.password.label')"
                     v-model="old_password"
              >
              <template v-if="vuelidate.old_password.$dirty">
                <span class="error pt-1" v-if="!vuelidate.old_password.required">
                  {{ $t('users_management.messages.validation.old_password_required') }}
                </span>
                <span class="error pt-1" v-if="!vuelidate.old_password.maxLength">
                  {{ $t('users_management.messages.validation.old_password_maxlength') }}
                </span>
                <span class="error pt-1" v-if="!vuelidate.old_password.minLength">
                  {{ $t('users_management.messages.validation.old_password_minlength') }}
                </span>
              </template>
            </div>
          </div>
          <div class="modal-field-content p__top--20">
            <div class="is-full m__top--10">
              <label class="input-title fs-14 color__blue_main fw__bold">{{ $t('users_management.labels.change.password.new') }}
              </label>
            </div>
            <div class="control is-full">
              <input type="password" class="input input-modal"
                     :placeholder="$t('users_management.labels.change.password.new')"
                     v-model="new_password"
              >
              <template v-if="vuelidate.new_password.$dirty">
                <span class="error pt-1" v-if="!vuelidate.new_password.required">
                  {{ $t('users_management.messages.validation.new_password_required') }}
                </span>
                <span class="error pt-1" v-if="!vuelidate.new_password.maxLength">
                  {{ $t('users_management.messages.validation.new_password_maxlength') }}
                </span>
                <span class="error pt-1" v-if="!vuelidate.new_password.minLength">
                  {{ $t('users_management.messages.validation.new_password_minlength') }}
                </span>
              </template>
            </div>
          </div>
          <div class="modal-field-content p__top--20">
            <div class="is-full m__top--10">
              <label class="input-title fs-14 color__blue_main fw__bold">
                {{ $t('users_management.labels.change.password.confirm') }}
              </label>
            </div>
            <div class="is-full control">
              <input type="password" class="input input-modal"
                     :placeholder="$t('users_management.labels.change.password.confirm')"
                     v-model="password_confirmation"
              >
              <template v-if="vuelidate.password_confirmation.$dirty">
                <span class="error pt-1" v-if="!vuelidate.password_confirmation.required">
                  {{ $t('users_management.messages.validation.password_confirm_required') }}
                </span>
                <span class="error pt-1" v-if="!vuelidate.password_confirmation.sameAs">
                  {{ $t('users_management.messages.validation.password_confirm_not_match') }}
                </span>
              </template>
            </div>
          </div>
        </section>
        <div class="modal-card-foot is-justify-content-center background__white">
          <button
              class="button is-success close-modal btn-save background__blue_main"
              @click="changePassword()"
                  >{{ $t('folders.buttons.save') }}
          </button>
          <button class="button close-modal btn-back background__white_pink color__btn_back"
                  @click="closeModal('modal-change-pass'); cancelCreateFolder()">{{
              $t('users_management.buttons.cancel')
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {ProfileService} from "@/services";
  import {maxLength, minLength, required, sameAs, decimal} from "vuelidate/lib/validators";
  import {katakana, extension, fileSizeValidation} from "@/helpers/utils";
  import i18n from "@/lang/i18n";
  import findIndex from 'lodash/findIndex'
  import {ACTION_SAVE_AUTH_USER} from "@/stores/auth/actions";
  import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions";
  import {emailRegexCheck} from "@/helpers/constant";
  // import {mapGetters} from 'vuex';

  export default {
    name: "Profile",
    props: {},
    data() {
      return {
        profile: {
          avatar: '',
          name: '',
          email: '',
          phone: '',
          katakana_name: '',
          full_name: '',
        },
        oldProfile: {},
        avatar: null,
        imgExt: ['png', 'jpeg', 'jpg', 'gif'],
        error_server: {},
        submitAvailable: true,
        password: '********',
        office: '',
        modalOpened: false,
        old_password: '',
        new_password: '',
        password_confirmation: '',
      }
    },
    validations: {
      profile: {
        avatar: {
          fileSizeValidation,
          extension: extension(['png', 'jpeg', 'bmp', 'jpg', 'gif'])
        },
        email: {
          required,
          validateEmail : (value) => {
            return emailRegexCheck.test(String(value));
          }
        },
        name: {
          required,
          maxLength: maxLength(64),
        },
        katakana_name: {
          katakana: (val) => {
            return katakana(val)
          },
          maxLength: maxLength(50),
        },
        full_name: {
          maxLength: maxLength(50),
        },
        phone: {
          maxLength: maxLength(13),
          minLength: minLength(9),
          decimal,
        },
      },
      old_password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(16),
      },
      new_password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(16),
      },
      password_confirmation: {
        required,
        sameAs: sameAs(function () {
          return this.new_password
        })
      },
    },
    watch: {},
    methods: {
      async getProfile() {
        ProfileService.detail().then((res) => {
          this.profile = Object.assign(this.profile, res.data)
          this.avatar = this.profile.avatar
          this.profile.avatar = null
          this.profile.phone = this.profile.phone || ''
          this.profile.full_name = this.profile.full_name || ''
          this.profile.katakana_name = this.profile.katakana_name || ''
          this.oldProfile = res.data;
          this.organization()
        }).catch((err) => {
          this.$toast.error(err.data.message)
        })
      },
      organization() {
        if (this.profile.office && this.profile.office.division && this.profile.office.division.branch) {
          this.office =  this.profile.office.division.branch.name + ' > ' + this.profile.office.division.name + ' > ' + this.profile.office.name
        } else {
          this.office = ''
        }
      },
      changeAvatar(e) {
        let avt = e.target.files[0];
        const ext = avt.name.split('.').pop()
        if (findIndex(this.imgExt, (e) => e === ext.toLowerCase()) >= 0) {
          this.avatar = URL.createObjectURL(avt)
          this.profile.avatar = avt
        } else {
          this.$toast.warning(i18n.t('profile.messages.validation.avatar.format_invalid'))
        }
      },

      submit() {
        this.error_server = {}
        this.vuelidate.profile.$touch();
        console.log(this.vuelidate)
        if (!this.vuelidate.profile.$invalid && this.submitAvailable) {
          let data = new FormData()
          Object.keys(this.profile).map((e) => {
            if (e !== 'role' && e !== 'office')
              data.append(e, this.profile[e])
          })
          !this.profile.avatar && data.delete('avatar')
          !this.profile.password && data.delete('password')
          !this.profile.new_password && data.delete('new_password')
          !this.profile.password_confirmation && data.delete('password_confirmation')
          // !this.profile.full_name && data.delete('full_name')
          // !this.profile.katakana_name && data.delete('katakana_name')
          // !this.profile.phone && data.delete('phone')
          data.append('_method', 'PUT')
          this.submitAvailable = false
          ProfileService.update(this.profile.id, data)
            .then(() => {
              this.submitAvailable = true
              this.$toast.success(this.$t('profile.messages.update_success'))
              this.$store.dispatch(ACTION_SAVE_AUTH_USER)
              this.vuelidate.$reset()
            })
            .catch((err) => {
              this.submitAvailable = true
              this.error_server = err.data.errors
            })
        }
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      reset() {
        this.profile = Object.assign({}, this.oldProfile);
        this.$router.go(-1)
      },
      closeModal(id) {
        if (this.modalOpened) {
          this.$nextTick(() => {
            if (this.$el.querySelector(`#${id}`)) {
              this.$el.querySelector(`#${id}`).classList.remove('is-active')
            }
          })
          setTimeout(() => {
            this.modalOpened = false
          })
        }
      },
      cancelCreateFolder() {
        this.displayTooltip = true
      },
      toggleModal(id) {
        this.$nextTick(() => {
          if (this.$el.querySelector(`#${id}`)) {
            this.$el.querySelector(`#${id}`).classList.toggle('is-active')
          }
        })
      },
      modalPassword() {
        this.vuelidate.old_password.$reset()
        this.vuelidate.new_password.$reset()
        this.vuelidate.password_confirmation.$reset()
        this.toggleModal('modal-change-pass')
        setTimeout(() => {
          this.modalOpened = true
        })
      },
      changePassword() {
        this.vuelidate.$touch()
        if (!this.vuelidate.old_password.$invalid &&
            !this.vuelidate.new_password.$invalid &&
            !this.vuelidate.password_confirmation.$invalid
        ) {
          let data = new FormData()
          data.append('old_password', this.old_password)
          data.append('new_password', this.new_password)
          data.append('password_confirmation', this.password_confirmation)
          ProfileService.changePassword(data).then(() => {
            this.$toast.success(this.$t('change_password.messages.success'))
            this.password_confirmation = ''
            this.old_password = ''
            this.new_password = ''
            this.closeModal('modal-change-pass')
          })
          .catch(() => {
            this.$toast.error(this.$t('change_password.messages.error'))
          })
        }
      }
    },
    created() {
      this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, '')
      this.getProfile();
    }
  }
</script>

<style lang="scss" scoped>
  .profile-box {
    background: $white;
    border-radius: 10px;
    margin: 0;
  }
  input {
    background: #E1F2FC !important;
    border: none;
    height: 40px !important;
    font-size: 14px !important;
    box-shadow: none !important;
    padding-left: 20px;
    color: #006FC3;
    font-weight: bold;
    &:hover {
      box-shadow: none;
    }
  }
  .input-modal {
    background: unset !important;
    border: 1px solid #E5E5E5;
    height: 40px !important;
    font-size: 12px !important;
    box-shadow: none !important;
    padding-left: 20px;
    color: #006FC3;
    &:hover {
      box-shadow: none;
    }
  }
  .change-pass-modal {
    padding:40px 100px;
  }
  .personal-image {
    text-align: left;
    label {
      input[type="file"] {
        display: none;
      }
      width: 80px;
      margin-right: 20px;
      .edit-avatar {
        background: #CCCCCC;
        height: 25px;
        border-radius: 12px;
        padding: 0 3px;
        span {
          font-size: 12px;
          color: #ffffff;
          margin-left: 10px;
        }
      }
    }

    .personal-figure {
      position: relative;
      width: 80px;
      height: 80px;
    }

    .personal-avatar {
      cursor: pointer;
      width: 80px;
      height: 80px;
      box-sizing: border-box;
      border-radius: 100%;
      transition: all ease-in-out .3s;
      object-fit: cover;
      &:hover {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
      }
    }
  }
  .input-profile {
    font-size: 14px;
    color: $blue_button;
    font-weight: bold;
  }
  .information .information-input {
    margin-top: 16px;
  }
  .btn-save {
    width: 140px;
    height: 35px !important;
    border-radius: 17px !important;
    font-weight: bold;
    color: $white !important;
    background: #006FC3  0% 0% no-repeat padding-box;
    font-size: 16px !important;
    border: none;
    &:hover {
      opacity: 0.5;
      box-shadow: none;
    }
  }
  .btn-back {
    width: 140px;
    height: 35px !important;
    border-radius: 17px !important;
    font-weight: bold;
    color: #989898 !important;
    background: #E5E5E5  0% 0% no-repeat padding-box;
    font-size: 16px !important;
    border: none;
    &:hover {
      opacity: 0.5;
      box-shadow: none;
    }
  }
  .modal-card-foot {
    padding-top: 40px !important;
    padding-bottom: 50px !important;
  }
  @media screen and (max-width: 1024px){
    .page-box-content {
      padding: 20px !important;
    }
    input {
      padding-left: 10px;
    }
    .information {
      display: flex;
    }
    .change-pass-modal {
      padding: 40px 50px;
    }
  }
</style>
